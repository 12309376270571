import { tagsDB, remoteTagsDB, destroyTagsDB } from './pouchdb';

export default {
  sync,
  load,
  updateUsage,
  deleteUsage,
  destroy,
  updateCategory
};

async function sync(readOnly = false) {
  let hasChanges = false;
  if (!remoteTagsDB()) return hasChanges;

  const from = await tagsDB().replicate.from(remoteTagsDB());
  if (from.docs_written > 0) hasChanges = true;
  if (readOnly) return hasChanges;

  await tagsDB().replicate.to(remoteTagsDB());

  return hasChanges;
}

function destroy() {
  return destroyTagsDB();
}

function load(kind) {
  return tagsDB()
    .allDocs({
      include_docs: true,
      start_key: `t${kind}`,
      end_key: `t${kind}\uffff`
    })
    .then(response =>
      response.rows.map(row => ({
        tag: row.doc._id.split('/')[1],
        usage: row.doc.usage
      }))
    )
    .then(docs => docs.sort((a, b) => b.usage - a.usage))
    .then(docs => docs.map(doc => doc.tag));
}

async function updateUsage(kind, tag, delta) {
  const id = `t${kind}/${tag}`;
  try {
    const doc = await tagsDB().get(id);
    await tagsDB().put({ ...doc, usage: parseInt(doc.usage, 10) + delta });
  } catch (err) {
    if (err.status !== 404) throw err;
    await tagsDB().put({ _id: id, usage: 1 });
  }

  await sync(false);
}

async function updateCategory(kind, oldTag, newTag) {
  const oldId = `t${kind}/${oldTag}`;
  const newId = `t${kind}/${newTag}`;

  try {
    const oldDoc = await tagsDB().get(oldId);

    await tagsDB().put({ _id: newId, usage: oldDoc.usage });

    await tagsDB().remove(oldDoc);

    await sync(false);
  } catch (err) {
    console.error("Error updating category:", err);
  }
}

function deleteUsage(kind, tag) {
    const id = `t${kind}/${tag}`;
    if (!id) return false;
    return tagsDB()
        .get(id)
        .then(doc =>
            tagsDB()
                .put({...doc, _deleted: true})
                .then(() => doc)
        )
        .catch(err => {
            if (err.status !== 404) throw err;
            alert("Deleting Error");
            return false;
        });
}
