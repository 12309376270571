import React from 'react';
import PropTypes from 'prop-types';

import { DropdownOption } from 'components/types';
import {connect} from "react-redux";
import {loadTags, addTagInSettings, deleteTagInSettings, editTagInSettings, editSaveTagInSettings} from "../../actions/entities/categories";
import {changeKind} from '../../actions/ui/form/transaction';

import {loadAccounts} from "../../actions/entities/accounts";
import {loadFilterTransactions} from "../../actions/entities/transactions";
import CategoriesEdit from "./CategoriesEdit";
import {applyFilters} from "../../actions/ui/transaction/filter";
import {getAccountsAsOptions, getAccountsCurrencyMap} from "../../selectors/entities/accounts";
import { getEditState, getTagOptions } from '../../selectors/entities/categories';


class CategoriesEditContainer extends React.Component {
    componentDidMount() {
        this.props.loadAccounts();
        this.props.loadTags();
        this.props.loadFilterTransactions();
    }
    onTagAdd = (value) => {
        const nextTag = {
            kind: this.props.form.kind,
            tags: [value]
        }
        this.props.addTagInSettings( nextTag);
    };

    onTagDelete = (value) => {
        this.props.deleteTagInSettings({ kind: this.props.form.kind, tags: value });
    };

    onTagEdit = (value, isEdit) => {
        this.props.editTagInSettings({ kind: this.props.form.kind, tags: value, isEdit });
    };

    onTagEditSave = (newValue) => {
        this.props.editSaveTagInSettings({ newValue });
    };

    render() {
        return (
           <CategoriesEdit
             props={this.props}
             onAddItem={this.onTagAdd}
             onDeleteItem={this.onTagDelete}
             onEditItem={this.onTagEdit}
             onEditSave={this.onTagEditSave}
           />
        )
    }
}

CategoriesEditContainer.propTypes = {
    linkedAccountId: PropTypes.string,
    form: PropTypes.shape({
        id: PropTypes.string,
        accountId: PropTypes.string,
        currency: PropTypes.string,
        linkedCurrency: PropTypes.string,
        kind: PropTypes.number
    }),
    accountCurrency: PropTypes.object.isRequired,
    accountOptions: PropTypes.arrayOf(DropdownOption).isRequired,
    tagsOptions: PropTypes.arrayOf(DropdownOption),
    changeKind: PropTypes.func.isRequired,
    addTag: PropTypes.func,
    deleteTagInSettings: PropTypes.func,
    changeTags: PropTypes.func,
    loadTags: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    accountOptions: getAccountsAsOptions(state),
    accountCurrency: getAccountsCurrencyMap(state),
    getCurrencyOptions: state.getCurrencyOptions,
    tagsOptions: getTagOptions(state),
    editItem: getEditState(state),
    activeKind: state.ui.form.transaction.kind,
    form: state.ui.form.transaction,
    loading: state.entities.tags.loading,
    error: state.entities.tags.error
});
export default connect( mapStateToProps, {
    loadAccounts,
    loadTags,
    loadFilterTransactions,
    applyFilters,
    addTagInSettings,
    deleteTagInSettings,
    editTagInSettings,
    editSaveTagInSettings,
    changeKind
})(CategoriesEditContainer);