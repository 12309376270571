import React, {useState} from "react";

import useInput from "../../util/use-input";
import './index.css';
import { postSentEmail } from '../../util/api';
import Modal from '../../components/Modals/Modal/Modal';
import { Dropdown, Form } from 'semantic-ui-react';
import { languages } from '../../util/languages';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validatePassword = (password) => {
  return String(password).trim().length >= 5
}

const CustomModal = ({ isOpen, onClose, onConfirm, sendEmailHasError }) => {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm(email);
  };

  return (
    <div className={`modal-overlay ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <h2 className="modal-header">{t('authorisation.enter_your_email')}</h2>
        <label className="modal-label">
          <input
            type="email"
            className="modal-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {sendEmailHasError && <p style={{marginTop: 0}} className="error-label">{t('authorisation.please_enter_a_valid_email')}</p>}
        </label>
        <div className="modal-buttons">
          <button className="modal-button modal-button-primary" onClick={handleConfirm}>
            {t('authorisation.send_reset_email')}
          </button>
          <button className="modal-button modal-button-secondary" onClick={onClose}>
            {t('authorisation.btn_close')}
          </button>
        </div>
      </div>
    </div>
  );
};

const Index = () => {
  const [isRegisterForm, setIsRegisterForm] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [failedModalOpen, setFailedModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [modalMessageError, setModalMessageError] = useState("");
  const [sendEmailHasError, setSendEmailHasError] = useState(false);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    changeHandler: emailChangeHandler,
    blurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(validateEmail);

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    changeHandler: passwordChangeHandler,
    blurHandler: passwordBlurHandler,
    reset: resetPassword,
  } = useInput(validatePassword);

  const {
    value: confirmPasswordValue,
    isValid: confirmPasswordIsValid,
    hasError: confirmPasswordHasError,
    changeHandler: confirmPasswordChangeHandler,
    blurHandler: confirmPasswordBlurHandler,
    reset: resetConfirmPassword,
  } = useInput((value) => value === passwordValue);

  let isFormValid = false;
  if (emailIsValid && passwordIsValid) {
    if (isRegisterForm) {
      isFormValid = confirmPasswordIsValid;
    } else {
      isFormValid = true;
    }
  }

  const submitHandler = async (ev) => {
    ev.preventDefault();

    if (!isFormValid) {
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setIsServerError(false);
    setServerErrorMessage("");
    setIsRegisterSuccess(false);

    const userInfo = {
      accessToken: '',
      refreshToken: '',
      email: '',
      userId: '',
      couchDB: {
        settings: '',
        accounts: '',
        transactions: '',
        tags: '',
      }
    };

    try {
      if (isRegisterForm) {
        const formData = new FormData();
        formData.append("EmailAddress", emailValue);
        formData.append("password", passwordValue);

        const requestOptions = {
          method: 'POST',
          body: formData,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, requestOptions);
        const result = await response.json();

        if (result.status) {
          throw new Error(result.detail ? result.detail : "Something go wrong");
        }

        setIsRegisterSuccess(true);
        setIsRegisterForm(false);
      } else {
        const formData = new FormData();
        formData.append("client_id", process.env.REACT_APP_OAUTH_CLIENT);
        formData.append("grant_type", "password");
        formData.append("username", emailValue);
        formData.append("password", passwordValue);

        const loginRequestOptions = {
          method: 'POST',
          body: formData,
        };

        const response = await fetch(`${process.env.REACT_APP_API_URL}/oauth`, loginRequestOptions);
        const result = await response.json();

        if (result.status) {
          throw new Error(result.detail ? result.detail : "Something go wrong");
        }

        userInfo.accessToken = result.access_token;
        userInfo.refreshToken = result.refresh_token;

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${result.access_token}`);

        const getInfoRequestOptions = {
          method: 'GET',
          headers: headers,
        };

        const getInfoResponse = await fetch(`${process.env.REACT_APP_API_URL}/user/${emailValue}`, getInfoRequestOptions);
        const getInfoResult = await getInfoResponse.json();

        if (getInfoResult.status) {
          throw new Error(getInfoResult.detail ? getInfoResult.detail : "Something go wrong");
        }

        userInfo.email = getInfoResult.emailAddress;
        userInfo.userId = getInfoResult.id;
        userInfo.couchDB.accounts = getInfoResult.couchTables.accounts;
        userInfo.couchDB.settings = getInfoResult.couchTables.settings;
        userInfo.couchDB.tags = getInfoResult.couchTables.tags
        userInfo.couchDB.transactions = getInfoResult.couchTables.transactions;
        userInfo.couchDB.username = getInfoResult.couchTables.username;
        userInfo.couchDB.password = getInfoResult.couchTables.password;
        userInfo.subscriptions = getInfoResult.subscriptions;

        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        window.location.href = '/';
      }

    } catch (e) {
      setIsServerError(true);
      if (e.message) {
        setServerErrorMessage(e.message);
      }
    } finally {
      setIsLoading(false);
      resetEmail();
      resetPassword();
      resetConfirmPassword();
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSendEmailHasError(false);
  };

  const handleSendEmail = async (email) => {
    if(validateEmail(email)) {
      setSendEmailHasError(false);
      setModalOpen(false);

      const [err, res] = await postSentEmail(email);

      if (err) {
        setFailedModalOpen(true);
        setModalMessageError(t("authorisation.this_email_was_not_registered"));
      } else {
        setSuccessModalOpen(true);
      }
    } else {
      setSendEmailHasError(true);
    }
  }

  const changeLanguage = (_, { value }) => {
    localStorage.setItem('selectedLanguage', value);
    i18n.changeLanguage(value);
  };

  const { t } = useTranslation();

  return (
    <div className="container-login">
      <div className="form-wrapper">
        {isLoading && (
          <div className="ui active inverted dimmer">
            <div className="ui text loader">Loading</div>
          </div>
        )}
        {isServerError && (
          <div className="ui warning message">
            <div className="header">
              {serverErrorMessage ? serverErrorMessage : t('authorisation.something_go_wrong')}
            </div>
          </div>
        )}
        {isRegisterSuccess && (
          <div className="ui positive message">
            <div className="header">
              {t('authorisation.registration_successful')}
            </div>
            <p>{t('authorisation.please_login')}</p>
          </div>
        )}
        <form className="ui form" onSubmit={submitHandler}>
          <div className={`field ${emailHasError && "error"}`}>
            <label htmlFor="email">{t('authorisation.email')}</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder=""
              value={emailValue}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
            />
            {emailHasError && <p className="error-label">{t('authorisation.please_enter_a_valid_email')}</p>}
          </div>
          <div className={`field ${passwordHasError && "error"}`}>
            <label htmlFor="password">{t('authorisation.password')}</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="******"
              value={passwordValue}
              onChange={passwordChangeHandler}
              onBlur={passwordBlurHandler}
            />
            {passwordHasError && <p className="error-label">{t('authorisation.password_must_be_at_least')}</p>}
          </div>
          {isRegisterForm && (
            <div className={`field ${confirmPasswordHasError && "error"}`}>
              <label htmlFor="password">{t('authorisation.confirm_password')}:</label>
              <input
                type="password"
                name="confirm_password"
                id="confirm_password"
                placeholder="******"
                value={confirmPasswordValue}
                onChange={confirmPasswordChangeHandler}
                onBlur={confirmPasswordBlurHandler}
              />
              {confirmPasswordHasError && <p className="error-label">{t('authorisation.password_must_be_equal')}</p>}
            </div>
          )}
          {isRegisterForm && (
            <Form>
              <Form.Group>
                <Form.Field>
                  <label>{t('labels.select_language')}</label>
                  <Dropdown
                    search
                    selection
                    onChange={changeLanguage}
                    options={_.map(languages, (l) => ({...l, text: t(`languages.${l.text}`)}))}
                    value={i18n.language}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
          {
            !isRegisterForm ? (<div className={'forgot-password'}>
              <span onClick={() => setModalOpen(true)}>{t('authorisation.forgot_password')}</span>
            </div>) : null
          }
          <button className='ui button primary' type='submit'>
          {isRegisterForm ? t('authorisation.btn_register') : t('authorisation.btn_login')}
          </button>
        </form>
        <div className="text-center new-toggle">
          <div className="ui divider"/>
          <h3 className="subheading">{isRegisterForm ? t('authorisation.already_have_an_account') : t('authorisation.new_to_monsyn')}</h3>
          <button className="ui button basic" type="button" onClick={() => {
            setIsRegisterForm(prevState => {
              return !prevState;
            });
          }}>
            {isRegisterForm ? t('authorisation.login_btn') : t("authorisation.btn_create_new_account")}
          </button>
        </div>
      </div>
      {
        isModalOpen ? <CustomModal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleSendEmail} sendEmailHasError={sendEmailHasError} /> : null
      }
      <Modal isOpen={failedModalOpen} onClose={() => setFailedModalOpen(false)} text={modalMessageError} color={"red"} />
      <Modal isOpen={successModalOpen} onClose={() => setSuccessModalOpen(false)} text={"Password reset link has been sent to your email!"} color={"green"} />
    </div>
  );
}

export default Index;
