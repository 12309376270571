import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Popup, Button, Form, Dropdown, Confirm } from 'semantic-ui-react';

import {isSignedIn} from '../../selectors/User';
import {signOutRequest} from '../../actions/User';
import i18n from 'i18next';
import { languages } from '../../util/languages';
import { setLanguage } from '../../actions/settings';
import { withTranslation } from 'react-i18next';
import { deletePermanentlyRemoveAccount } from '../../util/api';
import _ from 'lodash';

class User extends React.Component {
  state = {
    confirmationOpen: false,
  };

  changeLanguage = (event, { value }) => {
    this.props.setLanguage(value);
    i18n.changeLanguage(value);

    localStorage.setItem('selectedLanguage', value);
  };

  handleConfirm = async () => {
    const [err, res] = await deletePermanentlyRemoveAccount();

    if(res.success) {
      this.setState({ confirmationOpen: false });
      this.props.signOutRequest();
    }
  };

  handleCancel = () => {
    this.setState({ confirmationOpen: false });
  };

  signOutButtonLabel() {
    return this.props.isAuthenticated ? this.props.t('buttons.btn_sign_out') : this.props.t('buttons.btn_delete_data');
  }

  signOutButtonIcon() {
    return this.props.isAuthenticated ? 'sign out' : 'trash';
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Form>
          <Form.Group widths="five">
            <Form.Field>
              <label>{t('labels.select_language')}</label>
              <Dropdown
                search
                selection
                onChange={this.changeLanguage}
                options={_.map(languages, (l) => ({...l, text: t(`languages.${l.text}`)}))}
                value={this.props.selectedLanguage}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Popup
            on="click"
            trigger={
              <Button
                content={this.signOutButtonLabel()}
                icon={this.signOutButtonIcon()}
                labelPosition="right"
              />
            }
            header={t('messages.local_data_will_de_deleted')}
            content={
              <Button
                content={t('buttons.btn_confirm')}
                negative
                style={{ marginTop: '1em' }}
                floated="right"
                loading={this.props.isSignOutRunning}
                disabled={this.props.isSignOutRunning}
                onClick={() => {
                  this.props.signOutRequest();
                }}
              />
            }
          />
          {
            this.props.isAuthenticated && (
              <Button
                content={t('buttons.btn_permanently_remove')}
                icon="trash"
                labelPosition="right"
                onClick={() => this.setState({ confirmationOpen: true })}
              />
            )
          }
        </div>

        <Confirm
          open={this.state.confirmationOpen}
          content={t('messages.text_permanently_remove')}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          cancelButton={`${t('buttons.btn_cancel')}`}
          confirmButton={`${t('buttons.btn_ok')}`}
        />
      </>
    );
  }
}

User.propTypes = {
  isAuthenticated: PropTypes.bool,
  isSignOutRunning: PropTypes.bool,
  isSignOutComplete: PropTypes.bool,
  signOutRequest: PropTypes.func,
  selectedLanguage: PropTypes.string,
  setLanguage: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthenticated: isSignedIn(state),
  isSignOutRunning: state.user.signOutState === 'REQUEST',
  isSignOutComplete: state.user.signOutState === 'COMPLETE',
  selectedLanguage: state.settings.selectedLanguage,
});

export default connect(mapStateToProps, { signOutRequest, setLanguage })(withTranslation()(User));
