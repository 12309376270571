type CurrencyT = {
  name: string;
  symbol: string;
  exp: number;
  flag?: string;
  exchangeRate? : number
};

type AvailableCurrencyT = {
  [code: string]: CurrencyT;
};

const CURRENCY: AvailableCurrencyT = {
  AED: { name: 'Emirati Dirham', symbol: '.د.ب', exp: 2, flag: 'ae', exchangeRate: 5 },
  AFN: { name: 'Afghan Afghani', symbol: '؋', exp: 2, flag: 'af', exchangeRate: 5 },
  ALL: { name: 'Albanian lek', symbol: 'lek', exp: 2, flag: 'al', exchangeRate: 5 },
  AMD: { name: 'Armenian dram', symbol: 'AMD', exp: 2, flag: 'am', exchangeRate: 5 },
  ANG: { name: 'Dutch Guilder', symbol: 'ƒ', exp: 2, flag: 'an', exchangeRate: 5 },
  AOA: { name: 'Angolan Kwanza', symbol: 'Kz', exp: 2, flag: 'ao', exchangeRate: 5 },
  ARS: { name: 'Argentine peso', symbol: '$', exp: 2, flag: 'ar', exchangeRate: 5 },
  AUD: { name: 'Australian Dollar', symbol: '$', exp: 2, flag: 'au', exchangeRate: 5 },
  AWG: { name: 'Arubin florin', symbol: 'ƒ', exp: 2, flag: 'aw', exchangeRate: 5 },
  AZN: { name: 'Azerbaijani manat', symbol: 'ман', exp: 2, flag: 'az', exchangeRate: 5 },
  BAM: { name: 'Bosnian Convertible Marka', symbol: 'KM', exp: 2, flag: 'ba', exchangeRate: 5 },
  BBD: { name: 'Barbadian dollar', symbol: '$', exp: 2, flag: 'bb', exchangeRate: 5 },
  BDT: { name: 'Bangladeshi Taka', symbol: 'Tk', exp: 2, flag: 'bd', exchangeRate: 5 },
  BGN: { name: 'Bulgarian lev', symbol: 'лв', exp: 2, flag: 'bg', exchangeRate: 5 },
  BHD: { name: 'Bahraini Dinar', symbol: '.د.ب or BD', exp: 3, flag: 'bh', exchangeRate: 5 },
  BIF: { name: 'Burundian Franc', symbol: 'FBu', exp: 0, flag: 'bi', exchangeRate: 5 },
  BMD: { name: 'Bermudian dollar', symbol: '$', exp: 2, flag: 'bm', exchangeRate: 5 },
  BND: { name: 'Bruneian Dollar', symbol: '$', exp: 2, flag: 'bn', exchangeRate: 5 },
  BOB: { name: 'Bolivian Boliviano', symbol: '$b', exp: 2, flag: 'bo', exchangeRate: 5 },
  BRL: { name: 'Brazilian real', symbol: 'R$', exp: 2, flag: 'br', exchangeRate: 5 },
  BSD: { name: 'Bahamian dollar', symbol: 'B$', exp: 2, flag: 'bs', exchangeRate: 5 },
  BTN: { name: 'Bhutanese Ngultrum', symbol: 'Nu.', exp: 2, flag: 'bt', exchangeRate: 5 },
  BWP: { name: 'Botswana Pula', symbol: 'P', exp: 2, flag: 'bw', exchangeRate: 5 },
  BYR: { name: 'Belarusian ruble', symbol: 'р', exp: 2, flag: 'by', exchangeRate: 5 },
  BZD: { name: 'Belize dollar', symbol: 'BZ$', exp: 2, flag: 'bz', exchangeRate: 5 },
  CAD: { name: 'Canadian Dollar', symbol: '$', exp: 2, flag: 'ca', exchangeRate: 5 },
  CHF: { name: 'Swiss Franc', symbol: 'CHF', exp: 2, flag: 'ch', exchangeRate: 5 },
  CLP: { name: 'Chilean Peso', symbol: '$', exp: 0, flag: 'cl', exchangeRate: 5 },
  CNY: { name: 'Yuan or chinese renminbi', symbol: '¥', exp: 2, flag: 'cn', exchangeRate: 5 },
  COP: { name: 'Colombian peso', symbol: '$', exp: 2, flag: 'co', exchangeRate: 5 },
  CRC: { name: 'Costa Rican colón', symbol: '₡', exp: 2, flag: 'cr', exchangeRate: 5 },
  CUC: { name: 'Cuban convertible peso', symbol: '$', exp: 2, flag: 'cu', exchangeRate: 5 },
  CUP: { name: 'Cuban peso', symbol: '₱', exp: 2, flag: 'cu', exchangeRate: 5 },
  CVE: { name: 'Cape Verdean Escudo', symbol: '$', exp: 0, flag: 'cv', exchangeRate: 5 },
  CZK: { name: 'Czech koruna', symbol: 'Kč', exp: 2, flag: 'cz', exchangeRate: 5 },
  DJF: { name: 'Djiboutian Franc', symbol: 'fdj', exp: 0, flag: 'dj', exchangeRate: 5 },
  DKK: { name: 'Danish krone', symbol: 'kr', exp: 2, flag: 'dk', exchangeRate: 5 },
  DOP: { name: 'Dominican peso', symbol: '$', exp: 2, flag: 'do', exchangeRate: 5 },
  DZD: { name: 'Algerian Dinar', symbol: 'جد', exp: 2, flag: 'dz', exchangeRate: 5 },
  EGP: { name: 'Egyptian Pound', symbol: '£ ', exp: 2, flag: 'eg', exchangeRate: 5 },
  ERN: { name: 'Eritrean nakfa', symbol: 'ናቕፋ', exp: 2, flag: 'er', exchangeRate: 5 },
  ETB: { name: 'Ethiopian Birr', symbol: 'Br', exp: 2, flag: 'et', exchangeRate: 5 },
  EUR: { name: 'Euro', symbol: '€', exp: 2, flag: 'eu', exchangeRate: 5 },
  FJD: { name: 'Fijian dollar', symbol: '$', exp: 2, flag: 'fj', exchangeRate: 5 },
  FKP: { name: 'Falkland Island Pound', symbol: '£', exp: 2, flag: 'fk', exchangeRate: 5 },
  GBP: { name: 'British Pound', symbol: '£', exp: 2, flag: 'gb', exchangeRate: 5 },
  GEL: { name: 'Georgian lari', symbol: 'ლ', exp: 2, flag: 'ge', exchangeRate: 5 },
  GHS: { name: 'Ghanaian Cedi', symbol: 'GH¢', exp: 2, flag: 'gh', exchangeRate: 5 },
  GIP: { name: 'Gibraltar pound', symbol: '£', exp: 2, flag: 'gi', exchangeRate: 5 },
  GMD: { name: 'Gambian dalasi', symbol: 'D', exp: 2, flag: 'gm', exchangeRate: 5 },
  GNF: { name: 'Guinean Franc', symbol: 'GNF', exp: 0, flag: 'gn', exchangeRate: 5 },
  GTQ: { name: 'Guatemalan Quetzal', symbol: 'Q', exp: 2, flag: 'gt', exchangeRate: 5 },
  GYD: { name: 'Guyanese dollar', symbol: '$', exp: 2, flag: 'gy', exchangeRate: 5 },
  HKD: { name: 'Hong Kong dollar', symbol: 'HK$', exp: 2, flag: 'hk', exchangeRate: 5 },
  HNL: { name: 'Honduran lempira', symbol: 'L', exp: 2, flag: 'hn', exchangeRate: 5 },
  HRK: { name: 'Croatian kuna', symbol: 'kn', exp: 2, flag: 'hr', exchangeRate: 5 },
  HTG: { name: 'Haitian gourde', symbol: 'G', exp: 2, flag: 'ht', exchangeRate: 5 },
  HUF: { name: 'Hungarian forint', symbol: 'Ft', exp: 2, flag: 'hu', exchangeRate: 5 },
  IDR: { name: 'Indonesian Rupiah', symbol: 'Rp', exp: 2, flag: 'id', exchangeRate: 5 },
  ILS: { name: 'Israeli Shekel', symbol: '₪', exp: 2, flag: 'il', exchangeRate: 5 },
  INR: { name: 'Indian Rupee', symbol: '₹', exp: 2, flag: 'in', exchangeRate: 5 },
  IQD: { name: 'Iraqi Dinar', symbol: 'ع.د', exp: 3, flag: 'iq', exchangeRate: 5 },
  IRR: { name: 'Iranian Rial', symbol: '﷼', exp: 2, flag: 'ir', exchangeRate: 5 },
  ISK: { name: 'Icelandic Krona', symbol: 'kr', exp: 0, flag: 'is', exchangeRate: 5 },
  JMD: { name: 'Jamaican dollar', symbol: 'J$', exp: 2, flag: 'jm', exchangeRate: 5 },
  JOD: { name: 'Jordanian Dinar', symbol: 'JOD', exp: 3, flag: 'jo', exchangeRate: 5 },
  JPY: { name: 'Japanese yen', symbol: '¥', exp: 0, flag: 'jp', exchangeRate: 5 },
  KES: { name: 'Kenyan Shilling', symbol: 'KSh', exp: 2, flag: 'ke', exchangeRate: 5 },
  KGS: { name: 'Kyrgyzstani som', symbol: 'лв', exp: 2, flag: 'kg', exchangeRate: 5 },
  KHR: { name: 'Cambodian Riel', symbol: '៛', exp: 2, flag: 'kh', exchangeRate: 5 },
  KMF: { name: 'Comoro Franc', symbol: 'KMF', exp: 0, flag: 'km', exchangeRate: 5 },
  KPW: { name: 'North Korean won', symbol: '₩', exp: 2, flag: 'kp', exchangeRate: 5 },
  KRW: { name: 'South Korean won', symbol: '₩', exp: 0, flag: 'kr', exchangeRate: 5 },
  KWD: { name: 'Kuwaiti Dinar', symbol: 'ك', exp: 3, flag: 'kw', exchangeRate: 5 },
  KYD: { name: 'Caymanian Dollar', symbol: '$', exp: 2, flag: 'ky', exchangeRate: 5 },
  KZT: { name: 'Kazakhstani tenge', symbol: '₸', exp: 2, flag: 'kz', exchangeRate: 5 },
  LAK: { name: 'Lao or Laotian Kip', symbol: '₭', exp: 2, flag: 'la', exchangeRate: 5 },
  LBP: { name: 'Lebanese Pound', symbol: 'ل.ل', exp: 2, flag: 'lb', exchangeRate: 5 },
  LKR: { name: 'Sri Lankan Rupee', symbol: 'Rs', exp: 2, flag: 'lk', exchangeRate: 5 },
  LRD: { name: 'Liberian Dollar', symbol: '$', exp: 2, flag: 'lr', exchangeRate: 5 },
  LSL: { name: 'Lesotho loti', symbol: 'L or M', exp: 2, flag: 'ls', exchangeRate: 5 },
  LTL: { name: 'Lithuanian litas', symbol: 'Lt', exp: 2, flag: 'lt', exchangeRate: 5 },
  LYD: { name: 'Libyan Dinar', symbol: ' د.ل', exp: 3, flag: 'ly', exchangeRate: 5 },
  MAD: { name: 'Moroccan Dirham', symbol: 'م.د.', exp: 2, flag: 'ma', exchangeRate: 5 },
  MDL: { name: 'Moldovan Leu', symbol: 'L', exp: 2, flag: 'md', exchangeRate: 5 },
  MGA: { name: 'Malagasy Ariary', symbol: 'Ar', exp: 2, flag: 'mg', exchangeRate: 5 },
  MKD: { name: 'Macedonian Denar', symbol: 'ден', exp: 2, flag: 'mk', exchangeRate: 5 },
  MMK: { name: 'Burmese Kyat', symbol: 'K', exp: 2, flag: 'mm', exchangeRate: 5 },
  MNT: { name: 'Mongolian Tughrik', symbol: '₮', exp: 2, flag: 'mn', exchangeRate: 5 },
  MOP: { name: 'Macau Pataca', symbol: 'MOP$', exp: 2, flag: 'mo', exchangeRate: 5 },
  MRO: { name: 'Mauritanian Ouguiya', symbol: 'UM', exp: 2, flag: 'mr', exchangeRate: 5 },
  MUR: { name: 'Mauritian rupee', symbol: 'Rs', exp: 2, flag: 'mu', exchangeRate: 5 },
  MVR: { name: 'Maldivian Rufiyaa', symbol: 'rf', exp: 2, flag: 'mv', exchangeRate: 5 },
  MWK: { name: 'Malawian Kwacha', symbol: 'MK', exp: 2, flag: 'mw', exchangeRate: 5 },
  MXN: { name: 'Mexico Peso', symbol: '$', exp: 2, flag: 'mx', exchangeRate: 5 },
  MYR: { name: 'Malaysian Ringgit', symbol: 'RM', exp: 2, flag: 'my', exchangeRate: 5 },
  MZN: { name: 'Mozambican Metical', symbol: 'MT', exp: 2, flag: 'mz', exchangeRate: 5 },
  NAD: { name: 'Namibian Dollar', symbol: '$', exp: 2, flag: 'na', exchangeRate: 5 },
  NGN: { name: 'Nigerian Naira', symbol: '₦', exp: 2, flag: 'ng', exchangeRate: 5 },
  NIO: { name: 'Nicaraguan córdoba', symbol: 'C$', exp: 2, flag: 'ni', exchangeRate: 5 },
  NOK: { name: 'Norwegian krone', symbol: 'kr', exp: 2, flag: 'no', exchangeRate: 5 },
  NPR: { name: 'Nepalese Rupee', symbol: 'Rs', exp: 2, flag: 'np', exchangeRate: 5 },
  NZD: { name: 'New Zealand Dollar', symbol: '$', exp: 2, flag: 'nz', exchangeRate: 5 },
  OMR: { name: 'Omani Rial', symbol: 'ع.ر.', exp: 3, flag: 'om', exchangeRate: 5 },
  PAB: { name: 'Balboa panamérn', symbol: 'B/', exp: 2, flag: 'pa', exchangeRate: 5 },
  PEN: { name: 'Peruvian nuevo sol', symbol: 'S/', exp: 2, flag: 'pe', exchangeRate: 5 },
  PGK: { name: 'Papua New Guinean Kina', symbol: 'K', exp: 2, flag: 'pg', exchangeRate: 5 },
  PHP: { name: 'Philippine Peso', symbol: '₱', exp: 2, flag: 'ph', exchangeRate: 5 },
  PKR: { name: 'Pakistani Rupee', symbol: 'Rs', exp: 2, flag: 'pk', exchangeRate: 5 },
  PLN: { name: 'Polish złoty', symbol: 'zł', exp: 2, flag: 'pl', exchangeRate: 5 },
  PYG: { name: 'Paraguayan guarani', symbol: '₲', exp: 0, flag: 'py', exchangeRate: 5 },
  QAR: { name: 'Qatari Riyal', symbol: 'ق.ر ', exp: 2, flag: 'qa', exchangeRate: 5 },
  RON: { name: 'Romanian leu', symbol: 'lei', exp: 2, flag: 'ro', exchangeRate: 5 },
  RSD: { name: 'Serbian Dinar', symbol: 'РСД', exp: 2, flag: 'rs', exchangeRate: 5 },
  RUB: { name: 'Russian Rouble', symbol: '₽', exp: 2, flag: 'ru', exchangeRate: 5 },
  RWF: { name: 'Rwandan franc', symbol: 'FRw, RF, R₣', exp: 0, flag: 'rw', exchangeRate: 5 },
  SAR: { name: 'Saudi Arabian Riyal', symbol: 'ر.س', exp: 2, flag: 'sa', exchangeRate: 5 },
  SBD: { name: 'Solomon Islander Dollar', symbol: 'SI$', exp: 2, flag: 'sb', exchangeRate: 5 },
  SCR: { name: 'Seychellois Rupee', symbol: 'Rs', exp: 2, flag: 'sc', exchangeRate: 5 },
  SDG: { name: 'Sudanese Pound', symbol: 'SDG', exp: 2, flag: 'sd', exchangeRate: 5 },
  SEK: { name: 'Swedish krona', symbol: 'kr', exp: 2, flag: 'se', exchangeRate: 5 },
  SGD: { name: 'Singapore Dollar', symbol: '$', exp: 2, flag: 'sg', exchangeRate: 5 },
  SLL: { name: 'Sierra Leonean Leone', symbol: 'Le', exp: 2, flag: 'sl', exchangeRate: 5 },
  SOS: { name: 'Somali Shilling', symbol: 'S', exp: 2, flag: 'so', exchangeRate: 5 },
  SRD: { name: 'Surinamese dollar', symbol: '$', exp: 2, flag: 'sr', exchangeRate: 5 },
  SYP: { name: 'Syrian Pound', symbol: '£', exp: 2, flag: 'sy', exchangeRate: 5 },
  SZL: { name: 'Swazi Lilangeni', symbol: 'L or E', exp: 2, flag: 'sz', exchangeRate: 5 },
  THB: { name: 'Thai Baht', symbol: '฿', exp: 2, flag: 'th', exchangeRate: 5 },
  TJS: { name: 'Tajikistani somoni', symbol: 'TJS', exp: 2, flag: 'tj', exchangeRate: 5 },
  TMT: { name: 'Turkmenistan manat', symbol: 'T', exp: 2, flag: 'tm', exchangeRate: 5 },
  TND: { name: 'Tunisian Dinar', symbol: 'TND', exp: 3, flag: 'tn', exchangeRate: 5 },
  TOP: { name: "Tongan Pa'anga", symbol: 'T$', exp: 2, flag: 'to', exchangeRate: 5 },
  TRY: { name: 'Turkish Lira', symbol: '₺', exp: 2, flag: 'tr', exchangeRate: 5 },
  TTD: { name: 'Trinidadian dollar', symbol: 'TT$', exp: 2, flag: 'tt', exchangeRate: 5 },
  TWD: { name: 'Taiwan New Dollar', symbol: 'NT$', exp: 2, flag: 'tw', exchangeRate: 5 },
  TZS: { name: 'Tanzanian Shilling', symbol: 'Sh', exp: 2, flag: 'tz', exchangeRate: 5 },
  UAH: { name: 'Ukrainian Hryvnia', symbol: '₴', exp: 2, flag: 'ua', exchangeRate: 5 },
  UGX: { name: 'Ugandan Shilling', symbol: 'USh', exp: 0, flag: 'ug', exchangeRate: 5 },
  USD: { name: 'US Dollar', symbol: '$', exp: 2, flag: 'us', exchangeRate: 5 },
  UYU: { name: 'Uruguayan peso', symbol: '$U', exp: 2, flag: 'uy', exchangeRate: 5 },
  UZS: { name: 'Uzbekistani som', symbol: 'лв', exp: 2, flag: 'uz', exchangeRate: 5 },
  VEF: { name: 'Venezuelan bolivar', symbol: 'Bs', exp: 2, flag: 've', exchangeRate: 5 },
  VND: { name: 'Vietnamese Dong', symbol: '₫', exp: 0, flag: 'vn', exchangeRate: 5 },
  VUV: { name: 'Vanuatu Vatu', symbol: 'VT', exp: 0, flag: 'vu', exchangeRate: 5 },
  WST: { name: 'Samoan Tālā', symbol: '$', exp: 2, flag: 'ws', exchangeRate: 5 },
  YER: { name: 'Yemeni Rial', symbol: 'YER', exp: 2, flag: 'ye', exchangeRate: 5 },
  ZAR: { name: 'South African Rand', symbol: 'R', exp: 2, flag: 'za', exchangeRate: 5 },
  ZMW: { name: 'Zambian Kwacha', symbol: 'ZMK', exp: 2, flag: 'zm', exchangeRate: 5 },
  ZWD: { name: 'Zimbabwean Dollar', symbol: 'Z$', exp: 2, flag: 'zw', exchangeRate: 5 },
  XAU: { name: 'Gold, troy ounce', symbol: 'XAU', exp: 2, exchangeRate: 5 }
};

export interface ExchangeRateT {
  [code: string]: number;
}

type CurrencyOptionT = {
  key: string;
  value: string;
  text: string;
  flag?: string;
};

const Currency = {
  defaultBase: 'USD',
  options(): CurrencyOptionT[] {
    return Object.keys(CURRENCY).map(code => ({
      key: code,
      value: code,
      flag: CURRENCY[code].flag,
      text: `${code}, ${CURRENCY[code].name}`
    }));
  },
  name(code: string) {
    return CURRENCY[code].name;
  },
  symbol(code: string) {
    return CURRENCY[code].symbol;
  },
  minAmount(code: string) {
    return Number(`1e-${CURRENCY[code].exp}`);
  },
  minAmountExchangeRate(code: string) {
    return Number(`1e-${CURRENCY[code].exchangeRate}`);
  },
  /**
   * Convert value to currency's subunit (e.g. cents for USD).
   * Subunit is the minimal currency unit and it is always whole integer.
   */
  numberToCents(value: string | number, code: string) {
    return Math.round(parseFloat(`${value}e${CURRENCY[code].exp}`));
  },
  /**
   * Convert value from subunit back to float representation with formatting.
   * For example 199001 USD becomes 1,990.01 USD
   */
  centsToNumber(value: number, code: string): number {
    const exp = CURRENCY[code].exp;
    const num = Number(`${value}e-${exp}`);

    return num;
  },
  centsToString(value: number, code: string, format: boolean = true): string {
    const exp = CURRENCY[code].exp;
    const num = Number(`${value}e-${exp}`);

    return format
      ? num.toLocaleString("en-US", {
        minimumFractionDigits: exp,
        maximumFractionDigits: exp
      })
      : num.toString();
  },
  centsToDollar(value: number, code: string): string {
    const exp = CURRENCY[code].exp;
    const num = Number(`${value}e-${exp}`);

    return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
  },
  convert(value: number, rate: number, from: string, to: string) {
    return (value / rate) * Math.pow(10, CURRENCY[from].exp - CURRENCY[to].exp);
  }
};

export default Currency;
