import {handleActions} from 'redux-actions';

import {
  loadExpenseTagsSuccess,
  loadIncomeTagsSuccess,
  addTagInSettings,
  addTagSuccessInSettings,
  addTagFailInSettings,
  deleteTagInSettings,
  deleteTagSuccessInSettings,
  deleteTagFailInSettings,
  editTagInSettings,
  editSaveTagInSettings,
  editSaveTagFailInSettings,
  editSaveTagSuccessInSettings
} from '../../actions/entities/categories';
import {addTag } from '../../actions/ui/form/transaction';
import {signOutSuccess} from '../../actions/User';
import {TransationKindT} from '../../entities/Transaction';

const {Expense, Transfer, Income} = TransationKindT;

const initialState = {
  [Expense]: ["Undefined-Expense"],
  [Transfer]: [],
  [Income]: ["Undefined-Income"],
  editItem: {
    kind: null,
    tag: null,
    isEdit: false,
  },
  loading: false,
  error: "Empty"
};

export default handleActions(
  {
    [loadExpenseTagsSuccess]: (state, {payload}) => ({
      ...state,
      [Expense]: payload.length > 0 ? payload.includes("Undefined-Expense") ? payload : ["Undefined-Expense", ...payload] :  initialState[Expense]
    }),
    [loadIncomeTagsSuccess]: (state, {payload}) => ({
      ...state,
      [Income]: payload.length > 0 ? payload.includes("Undefined-Income") ? payload : ["Undefined-Income", ...payload] : initialState[Income]
    }),
    [addTag]: (state, action) => {
      const {kind, tag} = action.payload;
      return state[kind].includes(tag)
        ? state
        : {...state, [kind]: state[kind].concat(tag)};
    },
    [addTagInSettings]: (state) => {
      return {...state, loading: true};
    },
    [addTagSuccessInSettings]: (state, action) => {
      const {kind, tags} = action.payload;
      return state[kind].includes(tags)
        ? state
        : {...state, [kind]: state[kind].concat(tags), loading: false};
    },
    [addTagFailInSettings]: (state) => {
      return { ...state, loading: false, error: "Tag is not added."}
    },
    [deleteTagInSettings]: (state) => {
      return {...state, loading: true, error: "Tag is not deleted."};
    },
    [deleteTagSuccessInSettings]: (state, action) => {
      const {kind, tags} = action.payload;
      const newState = state[kind].filter(id => id !== action.payload.tags);
      return state[kind].includes(tags)
        ? {...state, [kind]: newState, loading: false, error: "New error2"}
        : {...state, loading: false, error: "New error3"}
    },
    [deleteTagFailInSettings]: (state) => {
      return {...state, loading: false, error: "Tag is not deleted."}
    },
    [editTagInSettings]: (state, action) => {
      const {kind, tags, isEdit} = action.payload;
      return {
        ...state,
        editItem: { kind, tag: tags, isEdit }
      };
    },
    [editSaveTagInSettings]: (state) => ({
      ...state,
      loading: true
    }),
    [editSaveTagSuccessInSettings]: (state, { payload }) => {
      const { kind, oldTag, newTag } = payload;
      const updatedTags = state[kind].map(tag => (tag === oldTag ? newTag : tag));
      return {
        ...state,
        [kind]: updatedTags,
        editItem: { kind: null, tag: null, isEdit: false },
        loading: false
      };
    },
    [editSaveTagFailInSettings]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [signOutSuccess]: () => initialState,
  },
  initialState
);
