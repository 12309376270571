import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button } from 'semantic-ui-react';
import Calendar from './Calendar';
import Filters from './Filters';
import AppliedFilters from './AppliedFilters';
import {
  DateFilterRangeT,
  getDateRangeFilterOptions,
  defaultDateFilterRange,
  getDateFilterRangeStart,
  getDateFilterRangeEnd
} from 'entities/Transaction/DateFilterRange';
import { DropdownOption } from 'components/types';
import './index.css';
import {withTranslation} from 'react-i18next';


class Filter extends React.Component {
  options = getDateRangeFilterOptions();
  state = {
    selectedValue: defaultDateFilterRange,
  };

  handleDateRange = (value) => {
    if (value === DateFilterRangeT.custom) {
      this.props.toggleFilterCalendar();
      this.setState({ selectedValue: value });
    } else {
      this.props.changeFilterDate({
        dateStart: getDateFilterRangeStart(value),
        dateEnd: getDateFilterRangeEnd(value),
      });
      this.setState({ selectedValue: value });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="container-header">
          <Button.Group basic>
            <Button
              icon="plus"
              labelPosition="left"
              content={t('buttons.btn_new')}
              onClick={() =>
                this.props.openTransactionInModal({
                  ...(this.props.selectedAccount
                    ? { accountId: this.props.selectedAccount }
                    : {})
                })
              }
            />
            <Dropdown
              button
              className="icon"
              text={this.props.dateRangeLabel}
              labeled
              icon="calendar"
            >
              <Dropdown.Menu>
                {this.options.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    text={t(`transaction.${option.text}`)}
                    onClick={() => this.handleDateRange(option.value)}
                    onMouseDown={(e) => e.preventDefault()}
                    className={this.state.selectedValue === option.value ? 'active selected' : ''}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              icon="filter"
              labelPosition="left"
              content={t('buttons.btn_filter')}
              onClick={this.props.toggleFilterModal}
            />
          </Button.Group>
          <Calendar {...this.props} />
          <Filters {...this.props} />
        </div>
        <AppliedFilters {...this.props} />
      </React.Fragment>
    );
  }
}

Filter.propTypes = {
  isMobile: PropTypes.bool,
  dateRangeLabel: PropTypes.string,
  isCalendarOpen: PropTypes.bool,
  isFilterModalOpen: PropTypes.bool,
  selectedAccount: PropTypes.string,
  appliedAccounts: PropTypes.arrayOf(PropTypes.string),
  accountOptions: PropTypes.arrayOf(DropdownOption),
  accountNameMap: PropTypes.object,
  appliedTags: PropTypes.arrayOf(PropTypes.string),
  tagsOptions: PropTypes.arrayOf(DropdownOption),
  changeFilterDate: PropTypes.func,
  toggleFilterCalendar: PropTypes.func,
  toggleFilterModal: PropTypes.func,
  applyFilters: PropTypes.func,
  openTransactionInModal: PropTypes.func
};

export default withTranslation() (Filter);
