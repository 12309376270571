import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm} from 'semantic-ui-react';
import Header from "../Transaction/Form/Header";
import {useTranslation} from 'react-i18next';

const CategoriesEdit = (props) => {
    const { t } = useTranslation();
    const initOptionsState = [{}];
    const [tagsOptions, newTagsOptions] = useState(initOptionsState);
    useEffect (() => {
        newTagsOptions(props.props.tagsOptions)
    }, [props.props.tagsOptions])

    const [newItem, changeNewItem] = useState("");
    const [confirmDelete, changeConfirmDelete] = useState(false);
    const [confirmAdd, changeConfirmAdd] = useState(false);
    const [keyEdit, changeKeyEdit] = useState(null);
    const [confirmContent, changeConfirmContent] = useState("");
    const [editValue, setEditValue] = useState("");

    const deleteItem = (key)=> {
        changeConfirmDelete(true);
        changeKeyEdit(key)
    }
    const deleteItemCancel = ()=> {
        changeConfirmDelete(false);
    }
    const deleteItemConfirm = ()=> {
        if (keyEdit === "Undefined-Expense" || keyEdit === "Undefined-Income") {
            changeConfirmContent( keyEdit  + ` ${t('messages.category_cannot_be_deleted')} `)
            return
        }

        props.onDeleteItem(keyEdit);
        changeConfirmDelete(false);
    }
    const addItemCancel =()=> {
        changeConfirmAdd(false);
    }
    const addItemConfirm = ()=> {
        const newCategoryObject={
            key: newItem,
            value: newItem,
            text: newItem
        }
        const foundItem = tagsOptions.some((el) => el.key === newCategoryObject.key);
        if (foundItem)  {
            changeConfirmContent(`${t('transaction.category')} ` + newItem +  ` ${t('messages.already_exists')}`);
            return
        }
        props.onAddItem( newItem);
        changeConfirmAdd(false);
        changeNewItem('');
    }
    const addItem = ()=> {
        if(!newItem) return;

        changeConfirmAdd(true);
    }
    const changeKind=(e)=>{
        props.props.changeKind(e);
    }

    const editItem = (key, isEdit) => {
      props.onEditItem(key, isEdit);
    }

    const editSave = () => {
      props.onEditSave(editValue);
    }

    return (
      <React.Fragment>
          <Header
            //withTransfer={!!props.form.linkedAccountId}
            activeKind={props.props.activeKind}
            changeKind={changeKind}
          />
        <React.Fragment>
          {tagsOptions &&
            tagsOptions.map(option => {
              const isEdit =
                props.props.editItem.kind === props.props.activeKind &&
                props.props.editItem.tag === option.key &&
                props.props.editItem?.isEdit;

              const isUndefinedCategory =
                option.value === 'Undefined-Expense' || option.value === 'Undefined-Income';

              return (
                <React.Fragment key={option.key}>
                  <div key={option.key} className="categories ui menu dropdown">
                    {isEdit ? (
                      <div className="wrapper-edit-input">
                        <input
                          style={{ display: 'block' }}
                          value={editValue}
                          onChange={e => setEditValue(e.target.value)}
                        />
                        <button onClick={editSave}>{t('buttons.btn_save')}</button>
                        <button onClick={() => editItem(option.key, false)}>{t('buttons.btn_cancel')}</button>
                      </div>
                    ) : (
                      <>
                        <div className="item">
                          {isUndefinedCategory ? t(`transaction.${option.value}`) : option.value}
                        </div>
                        {!isUndefinedCategory && (
                          <>
                            <div className="item-edit">
                              <i
                                aria-hidden="true"
                                className="edit icon"
                                onClick={() => {
                                  setEditValue(option.value);
                                  editItem(option.key, true);
                                }}
                              ></i>
                            </div>
                            <div className="item-delete">
                              <i
                                aria-hidden="true"
                                className="delete icon"
                                onClick={() => deleteItem(option.key)}
                              ></i>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
        </React.Fragment>
        <div className='ui add multiple selection dropdown'>
          <div role='combobox' className='ui search selection dropdown'>
            <input autoComplete='off' className='search' placeholder={t('settings.add_new_category')} type='text'
                   onChange={(e) => changeNewItem(e.target.value)} value={newItem}
            />
          </div>
          <div className='transaction-form-grid__field'>
            <Button
                    fluid onClick={() => addItem()}
                  >
                      {t('settings.add_new_category')}
                  </Button>
              </div>
          </div>
          <Confirm content={`${t('messages.are_you_sure_you_want_to_delete')} ` + keyEdit + ` ${t('transaction.category')}?`}
                   open={confirmDelete}
                   onCancel={() => deleteItemCancel()}
                   onConfirm={() => deleteItemConfirm()}
                   cancelButton={`${t('buttons.btn_cancel')}`}
                   confirmButton={`${t('buttons.btn_ok')}`}
          />
          <Confirm content={`${t('messages.are_you_sure_you_want_to_add')} ` + newItem + ` ${t('transaction.category')}?`}
                   open={confirmAdd}
                   onCancel={() => addItemCancel()}
                   onConfirm={() => addItemConfirm()}
                   cancelButton={`${t('buttons.btn_cancel')}`}
                   confirmButton={`${t('buttons.btn_ok')}`}
          />
          <Confirm content={confirmContent}
                   open={!!confirmContent}
                   onCancel={() => {
                       changeConfirmContent("");
                       changeConfirmAdd(false);
                       changeConfirmDelete(false);
                   }}
                   onConfirm={() => {
                       changeConfirmContent("");
                       changeConfirmAdd(false);
                       changeConfirmDelete(false);
                   }}
                   cancelButton={`${t('buttons.btn_cancel')}`}
                   confirmButton={`${t('buttons.btn_ok')}`}
          />
          <Confirm
            content={`${t('transaction.category')} ` + `${newItem}` + ` ${t('messages.is_been_deleted')}`}
            open={props.loading}
          />
      </React.Fragment>
    );
}

CategoriesEdit.propTypes = {
    onAddItem: PropTypes.func,
    onDeleteItem: PropTypes.func
};

export default CategoriesEdit;
