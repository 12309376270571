import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Dropdown, Input, Segment } from 'semantic-ui-react';
import {
  TransationKindT,
  getKindLabel,
  formToState
} from 'entities/Transaction';
import { DropdownOption } from 'components/types';
import Header from './Header';
import Account from './Account';
import './index.css';
import ExchangeRate from "./ExchangeRate";
import _ from "lodash";
import {withTranslation} from 'react-i18next';
import { connect } from 'react-redux';


const { Expense, Transfer, Income } = TransationKindT;


class TransactionForm extends React.Component {
  state = { searchQuery: '' };

  onSubmit = event => {
    event.preventDefault();

    if(this.props.form.kind === 0 && (_.isEmpty(this.props.form.tags[0]) || this.props.form.tags[0][0] === undefined)) {
      this.props.form.tags[0] = ["Undefined-Expense"]
    } else if(this.props.form.kind === 2 && (_.isEmpty(this.props.form.tags[2]) || this.props.form.tags[2][0] === undefined)) {
      this.props.form.tags[2] = ["Undefined-Income"]
    }

    this.props.saveTransaction(formToState(this.props.form));
    this.props.form.tags[0] = [];
    this.props.form.tags[2] = [];
  };

  onChange = handler => (_, { value }) => handler(value);
  onChangeTag = handler => (_, { value }) => {
    if(value.length === 1 ) {
      handler(value);
    } else {
      this.onTagClose();
      handler([value[1]]);
    }
  }

  onAccountChange = handler => (_, { value }) => {
    handler({
      accountId: value,
      currency: this.props.accountCurrency[value]
    });
  };

  onTagAdd = (_, { value }) => {
    this.props.addTag({ kind: this.props.form.kind, tag: value });
  };
  onTagSearchChange = (_, { searchQuery }) => this.setState({ searchQuery });
  onTagClose = () => this.setState({ searchQuery: '' });

  getCurrencyOptions = accountId => {
    return this.props.accountCurrency[accountId].map(code => ({
      key: code,
      value: code,
      text: code
    }));
  };

  getGridClassName = () =>
    this.props.form.kind === Transfer
      ? 'transaction-form-grid single-line'
      : 'transaction-form-grid';

  NoAccounts() {
    return <div className="transactions-form__empty">{this.props.t(`transaction.transactions_form_empty`)}</div>
  };

  render() {
    if (!this.props.form.accountId) return this.NoAccounts();
    const { t, i18n, exchangeRateTable } = this.props;
   
    return (
      <React.Fragment>
        <Header
          withTransfer={!!this.props.form.linkedAccountId}
          activeKind={this.props.form.kind}
          changeKind={this.props.changeKind}
        />
        <Segment attached="bottom">
          <Form onSubmit={this.onSubmit} className="transaction-form">
            <Account
              label={this.props.form.kind === Income ? t(`transaction.to`) : t(`transaction.from`)}
              accountId={this.props.form.accountId}
              amount={this.props.form.amount}
              currency={this.props.form.currency}
              accountOptions={this.props.accountOptions}
              currencyOptions={this.getCurrencyOptions(
                this.props.form.accountId
              )}
              onAccountChange={this.onAccountChange(this.props.changeAccount)}
              onAmountChange={this.onChange(this.props.changeAmount)}
              onCurrencyChange={this.onChange(this.props.changeCurrency)}
            />
            {this.props.form.kind === Transfer && this.props.form.linkedCurrency !== this.props.form.currency && (
                <ExchangeRate
                    exchangeRate={this.props.form.exchangeRate}
                    currency={this.props.form.currency}
                    linkedCurrency={this.props.form.linkedCurrency}
                    onChangeExchangeRate={this.onChange(this.props.changeExchangeRate)}
                    exchangeRateTable={exchangeRateTable}
                />
            )}
            {this.props.form.kind === Transfer && (
              <Account
                label={t(`transaction.to`)}
                accountId={this.props.form.linkedAccountId}
                amount={this.props.form.linkedAmount}
                currency={this.props.form.linkedCurrency}
                accountOptions={this.props.accountOptions}
                currencyOptions={this.getCurrencyOptions(
                  this.props.form.linkedAccountId
                )}
                onAccountChange={this.onAccountChange(
                  this.props.changeLinkedAccount
                )}
                onAmountChange={this.onChange(this.props.changeLinkedAmount)}
                onCurrencyChange={this.onChange(this.props.changeLinkedCurrency)}
              />
            )}
            <div className={this.getGridClassName()}>
              <div className="transaction-form-grid__column-wide">
                {this.props.form.kind !== Transfer && (
                  <div className="transaction-form-grid__field">
                    <Form.Field>
                      <label>{t(`transaction.category`)}</label>
                      <Dropdown
                        multiple
                        selection
                        search
                        allowAdditions
                        closeOnChange
                        placeholder={t(`transaction.dropdown_placeholder`)}
                        value={this.props.form.tags[this.props.form.kind]}
                        options={_.map(this.props.tagsOptions, (o) => ({...o, text: o.text === 'Undefined-Expense' || o.text === 'Undefined-Income' ? t(`transaction.${o.text}`) : o.text}))}
                        onChange={this.onChangeTag(this.props.changeTags)}
                        onAddItem={this.onTagAdd}
                        onClose={this.onTagClose}
                        onSearchChange={this.onTagSearchChange}
                        searchQuery={this.state.searchQuery}
                      />
                    </Form.Field>
                  </div>
                )}
                <div className="transaction-form-grid__field">
                  <Form.Field>
                    <Input
                      placeholder={t(`transaction.input_placeholder`)}
                      value={this.props.form.note}
                      onChange={this.onChange(this.props.changeNote)}
                    />
                  </Form.Field>
                </div>
              </div>
              <div className="transaction-form-grid__column-narrow">
                <div className="transaction-form-grid__field">
                  <Form.Field>
                    <Input
                      required
                      fluid
                      type="date"
                      value={this.props.form.date}
                      onChange={this.onChange(this.props.changeDate)}
                    />
                  </Form.Field>
                </div>
                <div className="transaction-form-grid__field">
                  <Button
                    primary
                    fluid
                    disabled={parseFloat(this.props.form.amount) === 0}
                  >
                    {this.props.form.id ? t(`buttons.btn_save`) : i18n.language === 'de' ? t(`transaction.${getKindLabel(this.props.form.kind)}`) : t(`buttons.btn_add`)}{' '}
                    {i18n.language === 'de' ? t(`buttons.btn_add`) : t(`transaction.${getKindLabel(this.props.form.kind)}`)}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Segment>
      </React.Fragment>
    );
  }
}

TransactionForm.propTypes = {
  form: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.oneOf([Expense, Transfer, Income]),
    accountId: PropTypes.string,
    amount: PropTypes.string || PropTypes.number,
    currency: PropTypes.string,
    linkedAccountId: PropTypes.string,
    linkedAmount: PropTypes.string,
    linkedCurrency: PropTypes.string,
    tags: PropTypes.shape({
      [Expense]: PropTypes.arrayOf(PropTypes.string),
      [Income]: PropTypes.arrayOf(PropTypes.string)
    }),
    date: PropTypes.string,
    note: PropTypes.string
  }),
  accountCurrency: PropTypes.object.isRequired,
  accountOptions: PropTypes.arrayOf(DropdownOption).isRequired,
  tagsOptions: PropTypes.arrayOf(DropdownOption),
  changeKind: PropTypes.func.isRequired,
  changeAccount: PropTypes.func.isRequired,
  changeAmount: PropTypes.func.isRequired,
  changeCurrency: PropTypes.func.isRequired,
  changeLinkedAccount: PropTypes.func,
  changeLinkedAmount: PropTypes.func,
  changeLinkedCurrency: PropTypes.func,
  addTag: PropTypes.func,
  changeTags: PropTypes.func,
  changeDate: PropTypes.func.isRequired,
  changeNote: PropTypes.func.isRequired,
  loadTags: PropTypes.func,
  saveTransaction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  exchangeRateTable: state.settings.exchangeRate,
});
export default withTranslation()(connect(mapStateToProps)(TransactionForm));

