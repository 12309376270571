import React from 'react';
import 'react-day-picker/dist/style.css';
import { Modal, Button } from 'semantic-ui-react';
import { DayPicker, addToRange } from 'react-day-picker';
import { withTranslation } from 'react-i18next';
import { languagesMapper } from '../../../util';


class Calendar extends React.Component {
  state = {
    from: null,
    to: null
  };

  handleDayClick = (day) => {
    const range = addToRange(day, this.state);
    this.setState(range);
  };

  handleResetClick = () => {
    this.setState({
      from: null,
      to: null
    });
  };

  handleApplyClick = () => {
    const { from, to } = this.state;

    this.props.changeFilterDate({
      dateStart: from.setHours(0, 0, 0),
      dateEnd: (to && to.setHours(23, 59, 59)) || from.setHours(23, 59, 59),
    });
    this.props.toggleFilterCalendar();
  };

  render() {
    const { from, to } = this.state;
    const { i18n, t } = this.props;

    return (
      <Modal
        open={this.props.isCalendarOpen}
        onClose={this.props.toggleFilterCalendar}
        className="transactions-filter-modal"
        closeIcon
        size="small"
      >
        <Modal.Header>{t('messages.show_transactions_in_range')}</Modal.Header>
        <Modal.Content>
          <DayPicker
            fixedWeeks
            showOutsideDays
            dir={i18n.language === 'il' || i18n.language === 'sa' ? 'rtl' : 'ltr'}
            numberOfMonths={this.props.isMobile ? 1 : 2}
            selected={[from, {to, from}]}
            locale={languagesMapper[i18n.language]}
            onDayClick={this.handleDayClick}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button content={`${t('buttons.btn_reset')}`} onClick={this.handleResetClick} />
          <Button
            content={`${t('buttons.btn_save')}`}
            onClick={this.handleApplyClick}
            positive
            disabled={from === null && to === null}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation() (Calendar);
