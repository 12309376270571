import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  loadTags,
  loadExpenseTagsSuccess,
  loadIncomeTagsSuccess,
    addTagInSettings,
  addTagSuccessInSettings,
  addTagFailInSettings,
  deleteTagInSettings,
  deleteTagSuccessInSettings,
  deleteTagFailInSettings,
  editSaveTagInSettings,
  editSaveTagSuccessInSettings,
  editSaveTagFailInSettings
} from '../actions/entities/categories';
import { TransationKindT } from '../entities/Transaction';
import TagsStorage from '../util/storage/categories';
import difference from '../util/SetDifference';
import {getFilters} from "../selectors/ui/transaction/filter";
import TransactionsStorage from "../util/storage/transactions";
import {loadFilterTransactionsSuccess, saveTransaction} from "../actions/entities/transactions";
import {saveTransactionWithChangedCategorySaga} from "./transactions";


const { Expense, Income } = TransationKindT;

export function* loadTagsSaga() {
  const expenseTags = yield call(TagsStorage.load, Expense);
  yield put(loadExpenseTagsSuccess(expenseTags));
  const incomeTags = yield call(TagsStorage.load, Income);
  yield put(loadIncomeTagsSuccess(incomeTags));
}

export function* updateTagsUsage(prev, next) {
  const prevTags = new Set((prev && prev.tags) || []);
  const nextTags = new Set((next && next.tags) || []);

  for (const newTag of difference(nextTags, prevTags)) {
    yield call(TagsStorage.updateUsage, next.kind, newTag, 1);
  }
  for (const oldTag of difference(prevTags, nextTags)) {
    yield call(TagsStorage.updateUsage, prev.kind, oldTag, -1);
  }
}

export function* addTagInSettingsSaga(action) {
  const newTag = action.payload;
  try {
    yield put(addTagSuccessInSettings(newTag));
    yield call(TagsStorage.updateUsage, newTag.kind, newTag.tags, 1);
  } catch (error) {
    yield put(addTagFailInSettings(newTag.id));
  }
}

export function* deleteTagInSettingsSaga(action) {
  const tagForDelete = action.payload;
  try {
    const filters = yield select(getFilters);
    const transactions = yield call(TransactionsStorage.loadFiltered, filters);
    for (const [index, tx] of transactions.entries()) {
      yield saveTransactionWithChangedCategorySaga(
          saveTransaction({
            ...tx,
            tags: ( tx.tags[0] === action.payload.tags && tx.kind === 0)
              ?  ["Undefined-Expense"]
              : (tx.tags[0] === action.payload.tags && tx.kind === 2)
                ? ["Undefined-Income"]
                : tx.tags
          })
      );
    }
    yield put(loadFilterTransactionsSuccess(transactions));
    yield put(deleteTagSuccessInSettings(tagForDelete));
    yield call(TagsStorage.deleteUsage, tagForDelete.kind, tagForDelete.tags);
  } catch (error) {
    yield put(deleteTagFailInSettings(tagForDelete.id));
  }
}

export function* editSaveTagInSettingsSaga(action) {
  try {
    const { newValue } = action.payload;
    const tagForEdit = yield select(state => state.entities.tags.editItem);
    yield call(TagsStorage.updateCategory, tagForEdit.kind, tagForEdit.tag, newValue);
    yield put(editSaveTagSuccessInSettings({ kind: tagForEdit.kind, oldTag: tagForEdit.tag, newTag: newValue }));
  } catch (error) {
    yield put(editSaveTagFailInSettings(error.message));
  }
}

export default [
  takeLatest(loadTags, loadTagsSaga),
  takeLatest(addTagInSettings, addTagInSettingsSaga),
  takeLatest(deleteTagInSettings, deleteTagInSettingsSaga),
  takeLatest(editSaveTagInSettings, editSaveTagInSettingsSaga)
];
